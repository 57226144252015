import { PWSAccount } from '@120wateraudit/envirio-components/dist/models'
import { useLazyQuery, useQuery } from '@apollo/client'
import moment from 'moment'
import React, { FC, useEffect, useReducer, useState } from 'react'
import { connect } from 'react-redux'

import { AWS_BASE, downloadDocument } from 'src/API'
import { removeBatchDownload } from 'src/actions/batchDownloads'
import { startPolling, stopPolling } from 'src/actions/polling'
import { contactsClient } from 'src/apollo-clients'
import { ApplicationState } from 'src/reducers'
import AppSkeleton from 'src/router/Skeleton'
import { displayDownloadStarted } from 'src/utils/toast'
import { Flags, useConfig } from 'src/hooks'

import {
  COMMUNICATION_BATCHES_READY,
  GET_BATCH_INFO_FOR_DOWNLOAD
} from './data-access'

const PollingNotifications: FC<Props> = ({
  account,
  batchIds,
  children,
  isPolling,
  removeBatchDownload,
  startPolling,
  stopPolling
}) => {
  const commsTwoEnabled = useConfig(Flags.CommsTwoEnabled)

  const accountId = account?.id
  const [skipQuery, setSkipQuery] = useState<boolean>(true)
  const {
    data: communicationBatchesData,
    loading: communicationBatchesLoading,
    startPolling: communicationBatchesStartPolling,
    stopPolling: communicationBatchesStopPolling
  } = useQuery(COMMUNICATION_BATCHES_READY, {
    client: contactsClient,
    fetchPolicy: 'network-only',
    skip: skipQuery === true || commsTwoEnabled,
    variables: { accountId }
  })
  const contentReducer = (state: State, action: Actions): State => {
    if (action.type === DispatchType.BATCHREADY) {
      if (action.payload) {
        return {
          ...state,
          currentBatch: action.payload[0].id,
          isDownloading: true,
          isReady: true
        }
      }
    }

    if (action.type === DispatchType.DOWNLOAD) {
      if (action.payload.communicationBatchDownloadInfo) {
        const documentId =
          action.payload.communicationBatchDownloadInfo.documentId
        downloadDocument(
          `${AWS_BASE}/platform/documents/accounts/${accountId}/download/${documentId}`,
          `Batch_${batchIds[0]}_${moment().format('YYYYMMDD-HHmm')}.zip`
        )
        removeBatchDownload({ batchId: batchIds[0] })
      }
      if (batchIds.length > 1) {
        startPolling()
      } else {
        communicationBatchesStopPolling()
      }
      return {
        ...state,
        isDownloading: false,
        isReady: false
      }
    }
    return state
  }

  const [state, dispatch] = useReducer(contentReducer, {
    currentBatch: undefined,
    isDownloading: false,
    isReady: false
  })

  const [batchDownloadInfoQuery] = useLazyQuery(GET_BATCH_INFO_FOR_DOWNLOAD, {
    client: contactsClient,
    fetchPolicy: 'network-only',
    variables: {
      accountId,
      batchId: state.currentBatch
    },
    onCompleted: payload => dispatch({ payload, type: DispatchType.DOWNLOAD })
  })

  useEffect(() => {
    setSkipQuery(false)
    if (isPolling === true) {
      communicationBatchesStartPolling(10000)
    }
    if (isPolling === false) {
      communicationBatchesStopPolling()
    }
  }, [
    isPolling,
    communicationBatchesStartPolling,
    communicationBatchesStopPolling
  ])

  useEffect(() => {
    if (isPolling && communicationBatchesData.communicationBatchReady) {
      const batchReadyId =
        communicationBatchesData.communicationBatchReady.filter(
          batch => batch.id === batchIds[0]
        )
      if (batchReadyId.length > 0) {
        dispatch({
          payload: batchReadyId,
          type: DispatchType.BATCHREADY
        })
        stopPolling()
      }
    }
  }, [communicationBatchesData, batchIds, dispatch, isPolling, stopPolling])

  useEffect(() => {
    if (state.isReady === true) {
      displayDownloadStarted({ type: 'comm' })
    }
  }, [state.isReady])

  useEffect(() => {
    if (state.isDownloading === true) {
      batchDownloadInfoQuery()
    }
  }, [state.isDownloading, batchDownloadInfoQuery])

  if (communicationBatchesLoading) {
    return <AppSkeleton />
  }

  return children
}

interface Props {
  account?: PWSAccount
  batchIds: ApplicationState['batchDownloads']['pendingDownloads']
  children: any
  isPolling: boolean
  removeBatchDownload: typeof removeBatchDownload
  startPolling: typeof startPolling
  stopPolling: typeof stopPolling
}

interface State {
  currentBatch: undefined | number
  isDownloading: boolean
  isReady: boolean
}

enum DispatchType {
  BATCHREADY,
  RESETSTATE,
  DOWNLOAD
}

interface BatchData {
  batchReadyId: number
}

interface DownloadData {
  communicationBatchDownloadInfo: {
    batchId: number
    documentId: number
  }
}

interface BatchReadyAction {
  payload: BatchData
  type: DispatchType.BATCHREADY
}

interface DownloadAction {
  payload: DownloadData
  type: DispatchType.DOWNLOAD
}

type Actions = BatchReadyAction | DownloadAction

const mapStateToProps = (state: ApplicationState) => ({
  batchIds: state.batchDownloads.pendingDownloads,
  isPolling: state.polling.isPolling
})

const mapDispatchToProps = {
  removeBatchDownload,
  startPolling,
  stopPolling
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PollingNotifications)

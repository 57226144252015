export const __DEV__ = process.env.NODE_ENV === 'development'
export const NODE_ENV = process.env.NODE_ENV
export const GOOGLE_MAPS_API_KEY =
  process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''

export const IS_DEV_OR_STAGING =
  window.location.href.includes('pws-dev.120water') ||
  window.location.href.includes('pws-staging.120water') ||
  window.location.href.includes('localhost')

export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN || ''
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID || ''
export const CONFIGCAT_KEY = process.env.REACT_APP_CONFIGCAT_KEY || ''
export const SYNCFUSION_KEY = process.env.REACT_APP_SYNCFUSION_KEY || ''

export const AWS_ACCOUNT_ID = process.env.REACT_APP_AWS_ACCOUNT_ID || ''
export const AWS_IDENTITY_POOL_ID =
  process.env.REACT_APP_AWS_IDENTITY_POOL_ID || ''
export const AWS_REGION = process.env.REACT_APP_AWS_REGION || ''
export const ONE_SCHEMA_CLIENT_ID =
  process.env.REACT_APP_ONE_SCHEMA_CLIENT_ID || ''
export const ONE_SCHEMA_IS_DEV_MODE =
  process.env.REACT_APP_ONE_SCHEMA_IS_DEV_MODE || 'false'

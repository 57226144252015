import { faGear, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { CSSProperties, useMemo, useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { Container, Menu } from 'semantic-ui-react'
import styled from 'styled-components'

import { ReactComponent as Logo } from 'src/assets/images/Logo.svg'
import { Media } from 'src/components/Media'
import OverflowMenu from 'src/components/OverflowMenu'
import { useFeatures } from 'src/hooks'
import { useUser } from 'src/router/UserProvider'
import { pushRoute } from 'src/utils/navigation'
import AccountSwitcher from './AccountSwitcher'
import User from './User'
import { useGetCurrentUserAccountsQuery } from 'src/services'
import Help from './Help'

interface NavigationItem {
  name: string
  to: string
}

const useNavigationItems = (): [NavigationItem[], string] => {
  const {
    accountHasAssetsFeature: hasAssets,
    accountHasCommsFeature: hasComms,
    accountHasLSLProbabilityFeature: hasProbs,
    features,
    hasStateReporting
  } = useFeatures()
  const programTypes = features.filter(f => f.code.startsWith('PT:'))
  const onlyPF = programTypes.every(pt => pt.code === 'PT:PF')
  const noProgramTypes = programTypes.length === 0

  return useMemo(() => {
    return [
      [
        !noProgramTypes && { name: 'Programs', to: '/programs' },
        { name: 'Locations', to: '/locations' },
        !noProgramTypes && !onlyPF && { name: 'Samples', to: '/samples' },
        hasAssets && {
          name: 'Inventory',
          to: '/inventory/list'
        },
        hasStateReporting && {
          name: 'State Reporting',
          to: '/state-reporting'
        },
        hasComms && {
          name: 'Communications',
          to: '/communications'
        },
        hasProbs && {
          name: 'LSL Probability Finder',
          to: '/lsl-probability-finder'
        }
      ].filter(Boolean) as NavigationItem[],
      noProgramTypes ? '/inventory' : '/programs'
    ]
  }, [noProgramTypes, onlyPF, hasAssets, hasStateReporting, hasComms, hasProbs])
}

const Navigation = (): JSX.Element => {
  const [navItems, logoPath] = useNavigationItems()

  return (
    <Nav>
      <Container>
        <Media at="sm">
          <Menu fluid text>
            <Menu.Item>
              <NavLink to={logoPath}>
                <Logo />
              </NavLink>
            </Menu.Item>
            <OverflowNav
              actions={navItems.map(i => ({
                label: i.name,
                onClick: () => pushRoute(i.to)
              }))}
              triggerIcon="bars"
            />
            <SubNavigation />
          </Menu>
        </Media>
        <Media greaterThanOrEqual="md">
          <Menu fluid text>
            <Menu.Item>
              <NavLink to={logoPath}>
                <Logo />
              </NavLink>
            </Menu.Item>
            {navItems.map(item => (
              <Menu.Item as={NavItem} key={item.to} to={item.to}>
                {item.name}
              </Menu.Item>
            ))}
            <SubNavigation />
          </Menu>
        </Media>
      </Container>
    </Nav>
  )
}

const Nav = styled.nav`
  grid-area: header;
  max-height: 56px;
  background: white;
  display: flex;
  align-items: center;
`

const OverflowNav = styled(OverflowMenu)`
  width: 56px;
` as typeof OverflowMenu

const NavItem = styled(NavLink)`
  &&&.item {
    font-weight: bold;
    font-size: 14px;
    line-height: 1.14285714;
    color: #333;
    margin: 0 4px;
    &.active,
    &:hover {
      font-weight: bold;
      color: #00b5e1;
      box-shadow: #00b5e1 0px -3px 0px 0px inset;
    }
  }
` as typeof NavLink

const SubNavigation = ({ style }: { style?: CSSProperties }): JSX.Element => {
  const { user } = useUser()
  const isAdmin = !!user.permissions?.includes('pws:admin')
  const [hasMultipleAccounts, setHasMultipleAccounts] = useState(false)

  const { data, isLoading } = useGetCurrentUserAccountsQuery({
    filter: '',
    pageNumber: 0,
    pageSize: 25
  })

  useEffect(() => {
    if (!isLoading) {
      if (data && data.items && data.items.length > 1) {
        setHasMultipleAccounts(true)
      }
    }
  }, [data, isLoading])

  return (
    <Menu.Menu position="right" style={{ gap: '8px', ...style }}>
      <Menu.Item>
        <Help icon={faQuestionCircle} size="lg" />
      </Menu.Item>
      {isAdmin && (
        <Menu.Item as={NavItem} to="/administration">
          <FontAwesomeIcon icon={faGear} size="lg" />
        </Menu.Item>
      )}
      <Menu.Item as={User} />
      {hasMultipleAccounts && <Menu.Item as={AccountSwitcher} />}
    </Menu.Menu>
  )
}

export default Navigation

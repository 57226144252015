import { StaticImplements } from '../types/StaticImplements'

interface ErrorLogger {
  log(e: Error): void
}

@StaticImplements<ErrorLogger>()
class Logger {
  public static log(e: Error): void {
    // eslint-disable-next-line no-console
    console.error(e)
  }
}

export default Logger

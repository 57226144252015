import { registerLicense } from '@syncfusion/ej2-base'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import ReactGA from 'react-ga4'
import { init as initFullStory } from '@fullstory/browser'

import { SYNCFUSION_KEY, __DEV__ } from 'src/constants'
import GlobalProviders from 'src/providers/GlobalProviders'
import register, { unregister } from 'src/registerServiceWorker'
import Entry from './Entry'

import '@120wateraudit/envirio-components/src/styles.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

window.addEventListener('pageshow', event => {
  // refresh web page if the page is restored from bfcache.
  if (event.persisted) {
    window.location.reload()
  }
})

if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID ?? '')
}

if (process.env.REACT_APP_FULLSTORY_ID) {
  initFullStory({ orgId: process.env.REACT_APP_FULLSTORY_ID })
}

// Registering Syncfusion license key
registerLicense(SYNCFUSION_KEY)

ReactDOM.render(
  <GlobalProviders>
    <Entry />
  </GlobalProviders>,
  document.getElementById('root') as HTMLElement
)

__DEV__ ? unregister() : register()
